import { ScrollInfoCanvas } from "./scroll-info-canvas";

class Home {

    static loadSection() {
        let callback = (entries => {
            entries.forEach(entry => {
                if(entry.intersectionRatio > 0){
                    [...entry.target.getElementsByTagName('h1')[0].querySelectorAll('span')].forEach((span, idx) => {
                        setTimeout(() => {
                            span.style.transform = `translateY(0)`;
                        }, (idx+1) * 50)
                    })
                }
            })
        })
    
        let options = {
            rootMargin: '0px',
            threshold: 1.0
        }

        
        const homeSection = document.querySelector('.home-container');
        const h1CharArray = homeSection.getElementsByTagName('h1')[0].innerText;
        let html = '';
        for(let i = 0; i < h1CharArray.length; i++){
            html += `<span>${h1CharArray[i]}</span>`;
        }
        
        // for trademark
        html += `<span>&nbsp;&trade;</span>`;

        homeSection.getElementsByTagName('h1')[0].innerHTML = html;
        
        let observer = new IntersectionObserver(callback, options);
        observer.observe(homeSection);
    }
    
    static loadScrollInfoCanvas() {
        let scrollInfoCanvasHome = document.getElementById('scroll-info-canvas-home');
        new ScrollInfoCanvas().load(scrollInfoCanvasHome);
    }

    // static loadScrollInfoCanvas2() {
    //     Math.easeInOutCubic = function (t, b, c, d) {
    //         t /= d/2;
    //         if (t < 1) return c/2*t*t*t + b;
    //         t -= 2;
    //         return c/2*(t*t*t + 2) + b;
    //     };
    //     Math.easeOutCubic = function (t, b, c, d) {
    //         t /= d;
    //         t--;
    //         return c*(t*t*t + 1) + b;
    //     };
    //     Math.easeInOutQuint = function (t, b, c, d) {
    //         t /= d/2;
    //         if (t < 1) return c/2*t*t*t*t*t + b;
    //         t -= 2;
    //         return c/2*(t*t*t*t*t + 2) + b;
    //     };

    //     var scrollInfoCanvas = document.getElementById('scroll-info-canvas-home');
    //     // scrollInfoCanvas.width = window.innerWidth;
    //     // scrollInfoCanvas.height = window.innerHeight;

    //     var Chi = Chi || {};
    //     Chi.Math = Chi.Math || {};

    //     Chi.Math.random = function(){
    //         if(!this.lookupTable){
    //             this.lookupTable = []
    //             this.lookupTable_iterator = -1;
    //             for (var i=1e4; i--;) {
    //                 this.lookupTable.push(Math.random());
    //             }
    //         }
    //         return ++this.lookupTable_iterator >= this.lookupTable.length ? this.lookupTable[this.lookupTable_iterator=0] : this.lookupTable[this.lookupTable_iterator];
    //     };
        
    //     Chi.Throttle = function(fps){
    //         var _fps = fps;
    //         var _fps_interval = 1000/_fps;
    //         var _last = 0;
        
    //         this.fps_interval_past_is = function(){
    //             console.log('inside throttle');
    //             var now = Date.now();
    //             if((now - _last) > _fps_interval){
    //                 _last = now;
    //                 return true;
    //             }else{
    //                 return false;
    //             }
    //         }
    //     };

    //     Chi.Canvas = Chi.Canvas || {
    //         size_fit_container_set : function(canvas, canvas_container) {
    //             canvas.width  = canvas_container.width();
    //             canvas.height = canvas_container.height();
    //         },
    //         rgba_set : function(canvas, canvas_context, rgba) {
    //             canvas_context.fillStyle = 'rgba('+rgba+')';
    //             canvas_context.fillRect(0,0,canvas.width,canvas.height);
    //         }
    //     };

    //     Chi.Canvas.pixel = {
    //         alpha_has : function(pixels, i){
    //             return pixels[i * 4 + 3];
    //         }
    //     };

    //     Chi.Canvas.Particle = function(canvas, canvas_context, coordinate, rgba){
    //         var _canvas_context = canvas_context;
    //         var _x = coordinate.x;
    //         var _y = coordinate.y;
    //         var _rgba = rgba;
        
    //         this.draw = function(){
    //             _canvas_context.fillStyle = 'rgba('+_rgba+')';
    //             _canvas_context.fillRect(_x, _y, 1, 1);
    //         }
    //         this.coordinate_get = function(){
    //             return {x:_x, y:_y};
    //         };
    //         this.coordinate_set = function(coordinate){
    //             _x = coordinate.x;
    //             _y = coordinate.y;
    //         };
    //     };

    //     Chi.Canvas.Text_sand = function(canvas, text_parameter, particle_parameter){
    //         var FPS = 200;
    //         var _canvas = canvas;
    //         var _canvas_context = _canvas.getContext('2d');
    //         var _text_pixels = [];
    //         var _particles = [];
        
    //         _canvas_context.clearRect(0, 0, _canvas.width, _canvas.height);
    //         _text_mold_create();
    //         _text_pixels_get();
    //         _canvas_context.clearRect(0, 0, _canvas.width, _canvas.height);
    //         _particles_create();
    //         _animate();
        
    //         function _text_mold_create(){
    //             _canvas_context.font = text_parameter.style.font;
    //             _canvas_context.textBaseline = 'middle';
    //             _canvas_context.fillStyle = text_parameter.style.color;
    //             var text_size = _canvas_context.measureText(text_parameter.text);
    //             _canvas_context.fillText(
    //                 text_parameter.text,
    //                 Math.round(_canvas.width - text_size.width - 48),
    //                 Math.round(_canvas.height - 48)
    //                 // Math.round((_canvas.width / 2) - (text_size.width / 2)),
    //                 // Math.round(_canvas.height / 2)
    //             );
    //         }
        
    //         function _text_pixels_get(){
    //             var imageData = _canvas_context.getImageData(0, 0, _canvas.width, _canvas.height);
    //             var pixels = imageData.data;
    //             var pixels_length = imageData.width * imageData.height;
    //             for(var i = 0; i < pixels_length; i++){
    //                 if(
    //                     Chi.Canvas.pixel.alpha_has(pixels, i) 
    //                 ){
    //                     _text_pixels.push({
    //                         x    : i % imageData.width,
    //                         y    : ~~(i/imageData.width),
    //                         rgba : pixels[i * 4]+','+pixels[i * 4 + 1]+','+pixels[i * 4 + 2]+','+pixels[i * 4 + 3]/255/particle_parameter.particle_perPixel
    //                     });
    //                 }
    //             }
    //         }
        
    //         function _particles_create(){
    //             for(var i in _text_pixels){
    //                 for(var j = particle_parameter.particle_perPixel; j--;){
    //                     var coordinate = {
    //                         //x : Math.floor(_canvas.width * Chi.Math.random()),
    //                         //y : _canvas.height,
    //                         // x: 0,
    //                         // y : Math.floor(_canvas.height * Chi.Math.random()),
    //                         // x : Math.floor(_canvas.width * Chi.Math.random()),
    //                         // y : _canvas.height,
    //                         x: Math.floor(_canvas.width * Chi.Math.random()),
    //                         y : Math.floor(_canvas.height * Chi.Math.random()),
    //                     };
    //                     _particles.push({
    //                         particle : new Chi.Canvas.Particle(_canvas, _canvas_context, coordinate, _text_pixels[i].rgba),
    //                         origin : coordinate,
    //                         destination : _text_pixels[i],
    //                         distance : {
    //                             x : _text_pixels[i].x - coordinate.x,
    //                             y : _text_pixels[i].y - coordinate.y
    //                         },
    //                         tick_passed : 0
    //                     });
    //                 }
    //             }
    //         }
        
    //         function _animate(){
    //             var throttle  = new Chi.Throttle(FPS);
    //             var tick_total = particle_parameter.animation_speed / 1000 * FPS;
    //             var particle_animation_queue = [];
    //             (function loop(){
    //                 if(throttle.fps_interval_past_is()){
    //                     _canvas_context.clearRect(0, 0, _canvas.width, _canvas.height);
    //                     switch(particle_parameter.particleAnimateSequence){
    //                         case 'sequential':
    //                             for(var i = 0; _particles.length && i < Math.ceil(particle_parameter.maxParticlesToAddPerAnimationLoop*Chi.Math.random()); i++){
    //                                 particle_animation_queue.push(_particles.shift());
    //                             }
    //                             break;
    //                         default:
    //                             for(var i = 0; _particles.length && i < Math.ceil(particle_parameter.maxParticlesToAddPerAnimationLoop*Chi.Math.random()); i++){
    //                                 particle_animation_queue.push(_particles.splice(Math.floor(_particles.length*Chi.Math.random()),1)[0]);
    //                             }
    //                             break;
    //                     }
    //                     for(var i in particle_animation_queue){
    //                         if(particle_animation_queue[i].tick_passed < tick_total){
    //                             particle_animation_queue[i].particle.coordinate_set({
    //                                 x : Math.easeOutCubic  (particle_animation_queue[i].tick_passed, particle_animation_queue[i].origin.x,  particle_animation_queue[i].distance.x, tick_total),
    //                                 y : Math.easeInOutCubic(particle_animation_queue[i].tick_passed, particle_animation_queue[i].origin.y,  particle_animation_queue[i].distance.y, tick_total)
    //                             });
    //                             particle_animation_queue[i].tick_passed++;
    //                         }
    //                         particle_animation_queue[i].particle.draw();
    //                     }
    //                 }
    //                 requestAnimationFrame(loop);
    //             })();
    //         }
    //     };

    //     Chi.Canvas.Text_sand (
    //         scrollInfoCanvas,
    //         {
    //             text:'SCROLL \u{2193}',
    //             style: {
    //                 font:'1.5rem "Playfair Display"',
    //                 color:'#222'
    //             }
    //         },
    //         {
    //             particle_perPixel:1,
    //             animation_speed:1000,
    //             particleAnimateSequence:'random',
    //             //particleAnimateSequence:'sequential',
    //             maxParticlesToAddPerAnimationLoop:50
    //         }
    //     );

    // }
}

export {
    Home
}