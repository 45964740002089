class Preloader {
    static hidePreloader () {
        document.querySelector('.preloader-container').style.display = "none";
        //document.body.classList.remove('no-scroll');
        document.body.classList.remove('overflow-y-clip');
        document.documentElement.classList.remove('overflow-y-clip');
    }
}

export {
    Preloader
}