import { detectIncognito } from 'detectincognitojs';

class Favicon {

    static loadFavicon(){
        detectIncognito().then((result) => {
            //console.log(result.browserName, result.isPrivate);

            let lightSchemeIcon = document.querySelector('link#light-scheme-icon');
            let darkSchemeIcon = document.querySelector('link#dark-scheme-icon');
            
            if (result.isPrivate) {
                lightSchemeIcon.remove();
                document.head.append(darkSchemeIcon);
            } else {
                document.head.append(lightSchemeIcon);
                darkSchemeIcon.remove();
            }
        });
    }

}

export {
    Favicon
}