class NavMenu {

    static openNavMenu() {
        //document.body.classList.add('no-scroll');
        document.body.classList.add('overflow-y-clip');
        document.documentElement.classList.add('overflow-y-clip');
        var delayInMilliseconds = 10;
        setTimeout(function() {
            document.querySelector('.nav-menu-container').style.transform = `translateX(0)`;
        }, delayInMilliseconds);
    }

    static closeNavMenu() {
        document.querySelector('.nav-menu-container').style.transform = `translateX(-100vw)`;
        var delayInMilliseconds = 200;
        setTimeout(function() {
            //document.body.classList.remove('no-scroll');
            document.body.classList.remove('overflow-y-clip');
            document.documentElement.classList.remove('overflow-y-clip');
        }, delayInMilliseconds);
    }

    static onclickNavButtonAndNavAnchors() {
        const navBtn = document.querySelector('.nav-btn');
        const navMenuContainer = document.querySelector('.nav-menu-container');
        let navMenuOpen = false;

        navBtn.addEventListener('click', () => {
            if(!navMenuOpen) {
              navBtn.classList.add('open');
              navMenuOpen = true;
              NavMenu.openNavMenu();
            } else {
              navBtn.classList.remove('open');
              navMenuOpen = false;
              NavMenu.closeNavMenu();
            }
        });

        document.querySelectorAll('.nav-anchor').forEach(el => {
            el.addEventListener("click", function (e) {
                if(navMenuOpen) {
                    navBtn.classList.remove('open');
                    navMenuContainer.classList.remove('open');
                    navMenuOpen = false;
                    NavMenu.closeNavMenu();
                }
            })
        });
    }

    // static onMouseOverNavAnchors() {
    //     document.querySelectorAll('.nav-anchor').forEach(el => {
            
    //         el.addEventListener("mouseover", function (e) {
    //             const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    //             let interval = null;
    //             let iteration = 0;
    //             clearInterval(interval);
    //             interval = setInterval(() => {
    //                 e.target.innerText = e.target.innerText.split("")
    //                     .map((letter, index) => {
    //                         if(index < iteration) {
    //                             return e.target.dataset.value[index];
    //                         }
    //                         return letters[Math.floor(Math.random() * 26)]
    //                     }).join("");
    //                 if(iteration >= e.target.dataset.value.length){ 
    //                     clearInterval(interval);
    //                 };
    //                 iteration += 1 / 2;
    //             }, 30);
    //         })
    //     });

    // }
}

export {
    NavMenu
}