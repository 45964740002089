
class Contact {

    static loadContactCanvas() {
        const contactCanvas = document.getElementById('contact-canvas');
        let contactCanvasRect = contactCanvas.getBoundingClientRect();
        contactCanvas.width = contactCanvasRect.width;
        contactCanvas.height = contactCanvasRect.height;
        const contactCanvasContext = contactCanvas.getContext('2d');

        const fontColor = window.getComputedStyle(document.body).getPropertyValue('--clr-fg-1');
        const textString = "WISEKRAFT";

        contactCanvasContext.fillStyle = fontColor;
        contactCanvasContext.textAlign = 'center';
        contactCanvasContext.textBaseline = 'middle';
        contactCanvasContext.font = (0.15 * contactCanvasRect.width) + 'px Playfair Display SC';

        // print text
        contactCanvasContext.fillText(textString, contactCanvasRect.width/2, contactCanvasRect.height/2);

        class Particle {
            constructor(x, y, color, mouse){
                this.x = this.originX = x;
                this.y = this.originY = y;
                this.color = color;
                this.mouse = mouse;
                this.size = 2;

                // for particle movement on mouse move
                // distance
                this.dx = 0;
                this.dy = 0;
                // velocity
                this.vx = 0;
                this.vy = 0;
                // force
                this.force = 0;
                // angle
                this.angle = 0;
                // another distance
                this.distance = 0;
                // friction
                this.friction = 0.98;
                // ease
                this.ease = 0.2;
            }

            update(){
                this.dx = this.mouse.x - this.x;
                this.dy = this.mouse.y - this.y;
                this.distance = this.dx * this.dx + this.dy * this.dy;
                this.force = -this.mouse.radius / this.distance;
                if(this.distance < this.mouse.radius) {
                    this.angle = Math.atan2(this.dy, this.dx);
                    this.vx += this.force * Math.cos(this.angle);
                    this.vy += this.force * Math.sin(this.angle);
                }
                this.x += (this.vx *= this.friction) + (this.originX - this.x) * this.ease;
                this.y += (this.vy *= this.friction) + (this.originY - this.y) * this.ease;
            }
        }

        // mouse statistics
        let mouse = {
            radius: 5000,
            x: 0,
            y: 0
        }

        // create particles array
        let particles = [];
        const particleSize = 5;
        var pixels = contactCanvasContext.getImageData(0, 0, contactCanvasRect.width, contactCanvasRect.height).data;
        var index;
        for(var y = 0; y < contactCanvasRect.height; y += particleSize) {
            for(var x = 0; x < contactCanvasRect.width; x += particleSize) {
                index = (y * contactCanvasRect.width + x) * 4;
                const red = pixels[index];
                const green = pixels[index + 1];
                const blue = pixels[index + 2];
                const alpha = pixels[index + 3];
                const color = 'rgb(' + red + ',' + green + ',' + blue + ')';
                if(alpha > 0) {
                    particles.push(new Particle(x, y, color, mouse));
                }
            }
        }

        // clear text
        contactCanvasContext.clearRect(0, 0, contactCanvasRect.width, contactCanvasRect.height);

        // draw particles
        drawParticles();
        
        window.addEventListener("mousemove", event => {
            mouse.x = event.pageX - contactCanvas.getBoundingClientRect().left - scrollX;
            mouse.y = event.pageY - contactCanvas.getBoundingClientRect().top - scrollY;
            //mouse.x = event.x;
            //mouse.y = event.y;
        });

        function drawParticles() {
            for(var i = 0; i < particles.length; i++) {
                var p = particles[i];
                contactCanvasContext.fillStyle = p.color;
                contactCanvasContext.fillRect(p.x, p.y, p.size, p.size);
            }
        }

        function updateParticlesOnMouseMove(){
            // update particle position
            for(var i = 0; i < particles.length; i++) {
                particles[i].update();
            }
            // clear
            contactCanvasContext.clearRect(0, 0, contactCanvasRect.width, contactCanvasRect.height);
            // draw particles as per new position
            drawParticles();
        }

        function animate() {
            // update on mouse move
            updateParticlesOnMouseMove();

            requestAnimationFrame(animate);
        }

        animate();
    }
    
}

export {
    Contact
}