import { Favicon } from "./partials/favicon";
import { Preloader } from "./partials/preloader";
import { NavMenu } from "./partials/nav-menu";
import { Home } from "./partials/home";
import { About } from "./partials/about";
import { Products } from "./partials/products";
import { Contact } from "./partials/contact-canvas";

window.onload = (event) => {
    
    //for preloader testing
    // setTimeout(function(){
    //     Preloader.hidePreloader();
    // }, 3000);
    
    Preloader.hidePreloader();
    Favicon.loadFavicon();
    NavMenu.onclickNavButtonAndNavAnchors();
    //NavMenu.onMouseOverNavAnchors();
    Home.loadSection();
    Home.loadScrollInfoCanvas();
    About.toggleAccordionItem();
    if (!isMobile()) {
        Contact.loadContactCanvas();
    }
}

window.onresize = (event) => {
    Home.loadScrollInfoCanvas();
    if (!isMobile()) {
        Contact.loadContactCanvas();
    }
}

window.onscroll = (event) => {
    Products.scrollProducts();
}


function isMobile(){
    // refer: _responsive.scss
    return window.innerWidth <= 599;
}