
class ScrollInfoCanvas {

    constructor() {}

    load (canvas) {
        this.canvas = canvas;
        // scrollInfoCanvas.width = window.innerWidth;
        // scrollInfoCanvas.height = window.innerHeight;
        this.canvasRect = this.canvas.getBoundingClientRect();
        this.canvas.width = this.canvasRect.width;
        this.canvas.height = this.canvasRect.height;
        this.canvasContext = this.canvas.getContext('2d');

        this.fontColor = window.getComputedStyle(document.body).getPropertyValue('--clr-fg-1');
        this.textString = "SCROLL \u{21E3}";

        this.canvasContext.fillStyle = this.fontColor;
        this.canvasContext.textAlign = 'center';
        this.canvasContext.textBaseline = 'middle';
        this.canvasContext.font = '1.7rem Playfair Display SC';

        this.particleArray = [];
        this.particleArrayWithRandomOrigin = [];
        this.particleSize = 2;

        this.maxParticlesToAddPerAnimationLoop = 50;
        this.fps = 200;
        this.animationSpeed = 1000;

        // increasing DPI
        // this.canvas.width = this.canvasRect.width * devicePixelRatio;
        // this.canvas.height = this.canvasRect.height * devicePixelRatio;
        // this.canvasContext.scale(devicePixelRatio, devicePixelRatio);

        this.clearCanvas();
        this.printText();
        this.particleArray = this.getParticles();
        this.particleArrayWithRandomOrigin = this.getParticlesWithRandomOrigin();
        this.animateParticles(this);
    }

    clearCanvas() {
        this.canvasContext.clearRect(
            0, 
            0, 
            this.canvasRect.width, 
            this.canvasRect.height);
    }

    printText() {
        this.canvasContext.fillText(
            this.textString, 
            Math.round(this.canvasRect.width - this.textString.length - 48*1.5),
            Math.round(this.canvasRect.height - 48)
            //this.canvasRect.width/2, 
            //this.canvasRect.height/2
        );
    }

    getParticles() {
        let particlesArrayLocal = [];
        let pixels = this.canvasContext.getImageData(0, 0, this.canvasRect.width, this.canvasRect.height).data;
        let index;
        for(let y=0; y<this.canvasRect.height; y+=this.particleSize) {
            for(let x=0; x<this.canvasRect.width; x+=this.particleSize) {
                index = (y * this.canvasRect.width + x) * 4;
                const red = pixels[index];
                const green = pixels[index + 1];
                const blue = pixels[index + 2];
                const alpha = pixels[index + 3];
                const rgba = 'rgb(' + red + ',' + green + ',' + blue + ')';
                if(alpha > 0) {
                    particlesArrayLocal.push(
                        {
                            x,
                            y,
                            rgba
                        }
                    );
                }
            }
        }
        return particlesArrayLocal;
    }

    getParticlesWithRandomOrigin(){
        let particlesArrayWithRandomOriginLocal = [];
        for(var i in this.particleArray){
            for(var j = this.particleSize; j--;){
                let coordinate = {
                    x: Math.floor(this.canvas.width * this.random()),
                    y : Math.floor(this.canvas.height * this.random()),
                }
                particlesArrayWithRandomOriginLocal.push({
                    particle : new Particle(this.canvas, this.canvasContext, coordinate, this.particleArray[i].rgba),
                    origin : coordinate,
                    destination : this.particleArray[i],
                    distance : {
                        x : this.particleArray[i].x - coordinate.x,
                        y : this.particleArray[i].y - coordinate.y
                    },
                    tickPassed : 0
                })
            }
        }
        return particlesArrayWithRandomOriginLocal;
    }

    animateParticles(scrollInfoCanvas) {
        var scrollInfoCanvasLocal = scrollInfoCanvas;
        var tickTotal = scrollInfoCanvasLocal.animationSpeed / 1000 * scrollInfoCanvasLocal.fps;
        
        var fpsLocal = scrollInfoCanvasLocal.fps;
        var fpsIntervalLocal = 1000/fpsLocal;
        var lastLocal = 0;

        var particleAnimationQueue = [];
        (
            function loop(){
                if( scrollInfoCanvasLocal.throttle(fpsIntervalLocal,lastLocal) ){

                    scrollInfoCanvasLocal.clearCanvas();

                    // console.log(scrollInfoCanvasLocal.particleArrayWithRandomOrigin.length);
                    //console.log(Math.ceil(scrollInfoCanvasLocal.maxParticlesToAddPerAnimationLoop * scrollInfoCanvasLocal.random()));

                    for(var i = 0; scrollInfoCanvasLocal.particleArrayWithRandomOrigin.length && i < Math.ceil(scrollInfoCanvasLocal.maxParticlesToAddPerAnimationLoop * scrollInfoCanvasLocal.random()); i++){
                        particleAnimationQueue.push(
                            scrollInfoCanvasLocal.particleArrayWithRandomOrigin.splice( 
                                Math.floor(scrollInfoCanvasLocal.particleArrayWithRandomOrigin.length * scrollInfoCanvasLocal.random()),1
                            )[0]
                        );
                    }

                    for(let i in particleAnimationQueue){
                        if(particleAnimationQueue[i].tickPassed < tickTotal){
                            particleAnimationQueue[i].particle.coordinateSet({
                                x : scrollInfoCanvasLocal.easeOutCubic (
                                    particleAnimationQueue[i].tickPassed, 
                                    particleAnimationQueue[i].origin.x,  
                                    particleAnimationQueue[i].distance.x, 
                                    tickTotal
                                    ),
                                y : scrollInfoCanvasLocal.easeInOutCubic(
                                    particleAnimationQueue[i].tickPassed, 
                                    particleAnimationQueue[i].origin.y, 
                                    particleAnimationQueue[i].distance.y, 
                                    tickTotal)
                            });
                            particleAnimationQueue[i].tickPassed++;
                        }
                        particleAnimationQueue[i].particle.draw();
                    }

                }
                
                requestAnimationFrame(loop);
            }
        )();
                
    }

    random(){
        if(!this.lookupTable){
            this.lookupTable = [];
            this.lookupTable_iterator = -1;
            for (var i=1e4; i--;) {
                this.lookupTable.push(Math.random());
            }
        }
        return ++this.lookupTable_iterator >= this.lookupTable.length ? this.lookupTable[this.lookupTable_iterator=0] : this.lookupTable[this.lookupTable_iterator];
    }

    throttle(fpsIntervalLocal,lastLocal) {
        //console.log('inside throttle');
        var now = Date.now();
        if((now - lastLocal) > fpsIntervalLocal){
            lastLocal = now;
            return true;
        }else{
            return false;
        }
    }

    easeInOutCubic = function (t, b, c, d) {
        t /= d/2;
        if (t < 1) return c/2*t*t*t + b;
        t -= 2;
        return c/2*(t*t*t + 2) + b;
    };

    easeOutCubic = function (t, b, c, d) {
        t /= d;
        t--;
        return c*(t*t*t + 1) + b;
    };
}

class Particle {
    constructor(canvas, canvasContext, coordinate, rgba) {
        this.canvas = canvas;
        this.canvasContext = canvasContext;
        this.x = coordinate.x;
        this.y = coordinate.y;
        this.rgba = rgba;
    }

    draw() {
        //console.log('inside draw method');
        this.canvasContext.fillStyle = 'rgba('+this.rgba+')';
        this.canvasContext.fillRect(this.x, this.y, 1, 1);
    }

    coordinateGet() {
        return { 
            x:this.x, 
            y:this.y 
        };
    };

    coordinateSet(coordinate) {
        this.x = coordinate.x;
        this.y = coordinate.y;
    };
}

export {
    ScrollInfoCanvas
}