class Products {
    static scrollProducts() {
        let productsSectionTop = document.getElementById('products').getBoundingClientRect().top;

        const products = [...document.querySelectorAll('.product')];

        for(let i = 0; i < products.length; i++){
            if(products[i].parentElement.getBoundingClientRect().top <= 1){    
                // +1 to account for the [product info] div  
                let offset = (productsSectionTop + (window.innerHeight * (i + 1))) * .0008;
                //console.log (i + ' ' + offset);
                offset = offset < -1 ? -1 : offset >= 0 ? 0 : offset;
                products[i].style.transform = `scale(${1 + offset})`
            }
        }
    }
}

export {
    Products
}